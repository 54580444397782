import React, { useEffect } from 'react';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import Home from './Components/Home';
import { loadScript } from './utils/loadScript'; // Import the loadScript function

function App() {
  useEffect(() => {
    // Dynamically load the scripts
    loadScript(`${process.env.PUBLIC_URL}/assets/js/vendor/jquery-1.12.4.min.js`)
      .then(() => loadScript(`${process.env.PUBLIC_URL}/assets/js/vendor/jquery-migrate-1.4.1.min.js`))
      .then(() => loadScript(`${process.env.PUBLIC_URL}/assets/js/popper.min.js`))
      .then(() => loadScript(`${process.env.PUBLIC_URL}/assets/js/bootstrap.min.js`))
      .then(() => loadScript(`${process.env.PUBLIC_URL}/assets/js/plugins.js`))
      .then(() => loadScript(`${process.env.PUBLIC_URL}/assets/js/map-place.js`))
      .then(() => loadScript(`${process.env.PUBLIC_URL}/assets/js/main.js`))
      .catch((error) => console.error("Error loading scripts:", error));
  }, []); // Empty dependency array means this runs once when the component mounts

  return (
    <>
      <Navbar />
      <Home />
      <Footer />
    </>
  );
}

export default App;
