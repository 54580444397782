import React from 'react'
import $ from 'jquery';

export default function Navbar() {
  return (
    <header className="header header-sticky">
    <div className="header-bottom menu-center">
        <div className="container">
            <div className="row justify-content-between">
                
                {/* <!--Logo start--> */}
                <div className="col mt-10 mb-10">
                    <div className="logo">
                        <a href="index.html"><img src="assets/images/logo.png" alt="" /></a>
                    </div>
                </div>
                {/* <!--Logo end--> */}
                
                {/* <!--Menu start--> */}
                <div className="col d-none d-lg-flex">
                    <nav className="main-menu">
                        <ul>
                            <li><a href="index.php">Home</a> </li>
                            <li className="has-dropdown"><a href="properties.html">sale</a>
                                <ul className="sub-menu">
                                    <li><a href="bunglow-sale.php">Bungalow</a> </li>
                                    <li><a href="flat-sale.php">Flat</a> </li>
                                    <li><a href="house-sale.php">House</a> </li>
                                    <li><a href="land-sale.php">Land</a> </li>
                                </ul>
                            </li>

                            <li className="has-dropdown"><a href="properties.html">Rent</a>
                                <ul className="sub-menu">
                                    <li><a href="bunglow-rent.php">Bungalow</a> </li>
                                    <li><a href="flat-rent.php">Flat</a> </li>
                                    <li><a href="house-rent.php">House</a> </li>
                                </ul>
                            </li>
                            <li><a href="about.php">about</a> </li>
                            <li><a href="blog.php">blog</a> </li>
                            <li><a href="contact.php">contact</a> </li>
                        
                        </ul>
                    </nav>
                </div>
                {/* <!--Menu end--> */}
                
                {/* <!--User start--> */}
                <div className="col mr-sm-50 mr-xs-50">
                    <div className="header-user">
                        {/* <!-- <a href="login-register.html" className="user-toggle"><i className="pe-7s-user"></i><span>Login or Register</span></a> --> */}
                    </div>
                </div>
                {/* <!--User end--> */}
            </div>
            
            {/* <!--Mobile Menu start--> */}
            <div className="row">
                <div className="col-12 d-flex d-lg-none">
                    <div className="mobile-menu"></div>
                </div>
            </div>
            {/* <!--Mobile Menu end--> */}
            
        </div>
    </div>
</header>
  )
}
