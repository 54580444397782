import React from 'react'

export default function Footer() {
  return (
     <footer className="footer-section section" style={{ backgroundImage: `url(assets/images/bg/footer-bg.jpg)` }} >
      
    {/* <!--Footer Top start--> */}
    <div
        className="footer-top section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50 pb-60 pb-lg-40 pb-md-30 pb-sm-20 pb-xs-10">
        <div className="container">
            <div className="row row-25">

                {/* <!--Footer Widget start--> */}
                <div className="footer-widget col-lg-3 col-md-6 col-12 mb-40">
                    <img src="assets/images/logo-footer.png" alt="" />
                    <p>Khonike - Real Estate Bootstrap 5 Templatethe best theme for elit, sed do to eiumod tempor
                        dolor sit amet, ctetur adipiscing elit seddo dolor sit amet.</p>
                    <div className="footer-social">
                        <a href="#" className="facebook"><i className="fa fa-facebook"></i></a>
                        <a href="#" className="twitter"><i className="fa fa-twitter"></i></a>
                        <a href="#" className="linkedin"><i className="fa fa-linkedin"></i></a>
                        <a href="#" className="google"><i className="fa fa-google-plus"></i></a>
                        <a href="#" className="pinterest"><i className="fa fa-pinterest-p"></i></a>
                    </div>
                </div>
                {/* <!--Footer Widget end--> */}



                {/* <!--Footer Widget start--> */}
                <div className="footer-widget col-lg-2 col-md-3 col-12 mb-40">
                    <h4 className="title"><span className="text">Sale</span><span className="shape"></span></h4>
                    <ul>
                        <li><a href="#">Bunglow</a></li>
                        <li><a href="#">Flat</a></li>
                        <li><a href="#">House</a></li>
                        <li><a href="#">Land</a></li>
                    </ul>
                </div>
                {/* <!--Footer Widget end--> */}

                   {/* <!--Footer Widget start--> */}
                   <div className="footer-widget col-lg-2 col-md-3 col-12 mb-40">
                    <h4 className="title"><span className="text">Rent</span><span className="shape"></span></h4>
                    <ul>
                        <li><a href="#">Bunglow</a></li>
                        <li><a href="#">Flat</a></li>
                        <li><a href="#">House</a></li>
                    </ul>
                </div>
                {/* <!--Footer Widget end--> */}

                {/* <!--Footer Widget start--> */}
                <div className="footer-widget col-lg-2 col-md-6 col-12 mb-40">
                    <h4 className="title"><span className="text">Useful links</span><span className="shape"></span></h4>
                    <ul>
                        <li><a href="#">Home </a></li>
                        <li><a href="#">About </a></li>
                        <li><a href="#">Blog </a></li>
                        <li><a href="#">FAQ </a></li>
                        <li><a href="#">Contact </a></li>
                    </ul>
                </div>
                {/* <!--Footer Widget end--> */}

                {/* <!--Footer Widget start--> */}
                <div className="footer-widget col-lg-3 col-md-6 col-12 mb-40">
                    <h4 className="title"><span className="text">Contact us</span><span className="shape"></span></h4>
                    <ul>
                        <li><i className="fa fa-map-o"></i><span>256, 1st AVE, Manchester 125 , Noth England</span></li>
                        <li><i className="fa fa-phone"></i><span><a href="#">+012 345 678 102</a><a href="#">+012 345
                                    678 101</a></span></li>
                        <li><i className="fa fa-envelope-o"></i><span><a href="#">info@example.com</a>
                        <a href="#">www.example.com</a></span></li>
                    </ul>
                </div>
                {/* <!--Footer Widget end--> */}

            </div>
        </div>
    </div>
    {/* <!--Footer Top end--> */}

    {/* <!--Footer bottom start--> */}
    <div className="footer-bottom section">
        <div className="container">
            <div className="row">
                <div className="col-6">
                    <div className="copyright text-start">
                        <p>Copyright &copy;2024 <a href="#" >Verified Propertise</a>. All rights reserved.</p>
                    </div>
                </div>
                <div className="col-6">
                    <div className="copyright text-end">
                        <p>Website Designed & Developed by <a href="https://technobizzar.com/" target="_blank">Technobizzar </a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!--Footer bottom end--> */}

</footer>
  )
}
